import classes from './ordersHeading.module.css';

export default function OrdersHeading(props) {
    const {activityList} = props
    return(
        <thead className={classes.root}>
            <tr>
                {props.state.CLIENT_CODE?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Client</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'CLIENT_CODE')}
                            value={props.state.CLIENT_CODE.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        /> 
                    </th> : null}
                {props.state.QUAI?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Code activité</p>
                        <select 
                        className={classes.input}
                        value={props.state.QUAI.input ?? ''}                         
                        onChange={(e) => props.handleChangeFilter(e.target.value, 'QUAI')}
                        >                            
                                <option value={''}></option>                     
                            {
                                activityList?.map((a,i)=>{
                                    return <option key={i} value={a}>{a}</option>
                                })
                            }       
                        </select>                        
                    </th> : null}
                {props.state.NUM_DOSSIER_TRANS?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>N° dossier</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'NUM_DOSSIER_TRANS')}
                            value={props.state.NUM_DOSSIER_TRANS.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.DATE_CREATION_TRANS?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Date création</p>
                        <input
                            // type="date"
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'DATE_CREATION_TRANS')}
                            value={props.state.DATE_CREATION_TRANS.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.NUM_CMD_CLIENT?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Ref. client</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'NUM_CMD_CLIENT')}
                            value={props.state.NUM_CMD_CLIENT.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.LIVRAISON_NOM?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Destinataire</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'LIVRAISON_NOM')}
                            value={props.state.LIVRAISON_NOM.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.LIVRAISON_CP?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Adresse</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'LIVRAISON_CP')}
                            value={props.state.LIVRAISON_CP.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.LIVRAISON_DATEPREVUE?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Date rdv</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'LIVRAISON_DATEPREVUE')}
                            value={props.state.LIVRAISON_DATEPREVUE.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.LIVRAISON_DATEREELLE?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Date liv</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'LIVRAISON_DATEREELLE')}
                            value={props.state.LIVRAISON_DATEREELLE.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.statut?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Statut</p>
                        <select 
                        className={classes.input}
                        value={props.state.statut?.input ?? ''}                         
                        onChange={(e) => props.handleChangeFilter(e.target.value, 'statut')}
                        >                            
                            <option value={''}></option>
                            <option value={'LOG'}>Logisitique - Tous</option>
                            <option value={'LOG_CRE'}>Logisitique - Prise en compte</option>
                            <option value={'LOG_RES'}>Logisitique - Marchandises réservées</option>
                            <option value={'LOG_RU1'}>Logisitique - Rupture Partielle</option>
                            <option value={'LOG_RU2'}>Logisitique - Rupture Totale</option>
                            <option value={'LOG_PRE'}>Logisitique - Préparation Commande</option>
                            <option value={'LOG_VAL'}>Logisitique - Commande prête pour Expédition</option>
                            <option value={'LOG_LIV|POD'}>Logisitique - Commande livrée</option>
                            <option value={'LOG_DIF'}>Logisitique - RDV pris / RDV refusé</option>
                            <option value={'LOG_AAR'}>Logisitique - Arrivage conforme</option>
                            {/* TRANS */}
                            <option value={'TRANS'}>Transport - Tous</option>
                            <option value={'TRANS_CRE'}>Transport - Prise en compte Transport</option>
                            <option value={'TRANS_DIF'}>Transport - RDV pris / RDV refusé</option>
                            <option value={'TRANS_LIV|POD'}>Transport - Commande livrée</option>
                            <option value={'TRANS_AAR'}>Transport - Arrivage conforme</option>
                        </select>
                    </th> : null}
                {props.state.NB_COLIS?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Colis</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'NB_COLIS')}
                            value={props.state.NB_COLIS.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.POIDS?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Poids</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'POIDS')}
                            value={props.state.POIDS.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.VOLUME?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Volume</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'VOLUME')}
                            value={props.state.VOLUME.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.NB_PAL?.visibility == true ?
                    <th>
                        <p className={classes.titleOrder}>Palettes</p>
                        <input
                            className={classes.input}
                            onChange={(e) => props.handleChangeFilter(e.target.value, 'NB_PAL')}
                            value={props.state.NB_PAL.input ?? ''}
                            onKeyDown={(e) => props.handleKeyDown(e)}
                        />
                    </th> : null}
                {props.state.pod?.visibility == true ? <th><p className={classes.without_input}>POD</p></th> : null}     
                {props.state.comments?.visibility == true ?
                    <th>
                        <p className={classes.without_input}>Commentaires</p>
                    </th> : null}           
            </tr>
        </thead>
    )
}